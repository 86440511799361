import React, {Component} from 'react';
import NavBar from '../components/NavBar';
import ContactUs from '../Form';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
import {animateScroll as scroll } from 'react-scroll';

class Contact extends Component {

    scrollToTop(){
        scroll.scrollToTop();
    }

    componentDidMount() {
        this.scrollToTop();
    }

    render(){
        return(
            <div>
                {/* Navigation bar */}
                <NavBar/>

                {/* breadcrumb */}
                {/*====================  breadcrumb area ====================*/}
                <div className="breadcrumb-area breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="page-banner text-center">
                                    <h1>Entre em contacto connosco</h1>
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Home</a></li>
                                        <li>Contactos</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                {/*====================  content page content ====================*/}
                <div className="page-wrapper section-space--inner--120">
                {/*Contact section start*/}
                <div className="conact-section">
                    <div className="container">
                    <div className="row section-space--bottom--50">
                        <div className="col">
                            <div className="contact-map">
                            <iframe id="form-contact-row" title="google-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3120.4908731655714!2d-8.880800384387504!3d38.54550277521392!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd19424a90bbb993%3A0xf8fac5c80318a2d!2sEstr.%20Vale%20de%20Mulatas%2010%2C%202910-737%20Charate!5e0!3m2!1spt-PT!2spt!4v1585607454600!5m2!1spt-PT!2spt" allowFullScreen></iframe>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-12">
                        <div className="contact-information">
                            <h3>Informação de contacto</h3>
                            <ul>
                                <li className="verticalCenter">
                                    <span className="icon"><i className="ion-android-map" /></span>
                                    <span className="text"><span>Estrada Vale de Mulatas, Lote 10, Arm. 4, Setúbal 2910-737, Setúbal</span></span>
                                </li>
                                <li className="verticalCenter">
                                    <span className="icon"><i className="ion-ios-telephone-outline" /></span>
                                    <span className="text"><a href="tel:+351964015371">+351 964 015 371</a></span>
                                </li>
                                <li className="verticalCenter">
                                    <span className="icon"><i className="ion-ios-telephone-outline" /></span>
                                    <span className="text"><a href="tel:+351265229187">+351 265 229 187</a></span>
                                </li>
                                <li className="verticalCenter">
                                    <span className="icon"><i className="ion-ios-email-outline" /></span>
                                    <span className="text"><a href="mailto:geral@bobiset.pt">geral@bobiset.pt</a></span>
                                </li>
                            </ul>
                        </div>
                        </div>
                        <div className="col-lg-8 col-12">
                        <div className="contact-form">
                            <h3>Deixe a sua mensagem</h3>
                            <ContactUs></ContactUs>

                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                {/*Contact section end*/}
                </div>
                {/*====================  End of content page content  ====================*/}
                
                {/* Footer */}
                <Footer/>

                {/* Mobile Menu */}
                <MobileMenu/>

            </div>
        )
    }
}


export default Contact;